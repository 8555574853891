import styled from 'styled-components';
import { device } from '../constants/device';

const Title = styled.h4`
  font-size: 20px;
  color: #2a2a2a;
  padding: 20px 0;
  word-break: break-word;
  &.bigText {
    font-size: 36px;
  }
  @media ${device.midDevice2} {
    min-height: 112px;
  }
`;
const TitleBody = styled.p`
  font-size: 18px;
  color: #969696;
  padding: 20px 0;
  @media ${device.tablet} {
    font-size: 14px;
    padding: 5px 0 10px;
  }
`;
const AuthorName = styled.span`
  font-size: 14px;
  color: #2a2a2a;
  padding: 10px 0;
  text-transform: uppercase;
  &.bigText {
    font-size: 20px;
  }
  @media ${device.tablet} {
    font-size: 12px;
    padding: 5px 0;
  }
`;
const AuthorDescription = styled.cite`
  font-size: 16px;
  color: #969696;
  padding: 5px 0;
  font-style: normal;
  display: block;
  @media ${device.tablet} {
    font-size: 12px;
    padding: 5px 0 0;
  }
`;
const ArticleDate = styled.em`
  font-size: 14px;
  color: #969696;
  padding: 5px 0;
  font-style: normal;
  display: block;
  &.bigText {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 12px;
    padding: 5px 0 0;
  }
`;
const ArticleImage = styled.img`
  width: 100%;
  display: block;
  background: #d8d8d8;
  border-radius: 4px;
  &.sm {
    height: 230px;
  }
  &.lg {
    height: auto;
    max-height: 400px;
    &.md {
      height: 400px;
      @media ${device.tablet} {
        height: 250px;
      }
    }
  }
`;
const AuthorAvatar = styled.img`
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background: #d8d8d8;
  &.small {
    height: 64px;
    width: 64px;
  }
`;
const Separator = styled.p`
  height: 1px;
  background: #d8d8d8;
  width: 100%;
  margin: 0px 0 5px 0;
`;
const ArticleFigure = styled.figure`
  cursor: pointer;
`;

export {
  Title,
  TitleBody,
  AuthorName,
  AuthorDescription,
  ArticleDate,
  ArticleImage,
  AuthorAvatar,
  Separator,
  ArticleFigure
};
