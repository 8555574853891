import React, { Component } from 'react';
import { ChevronIcon } from '../custom-icons';
import userServices from '../services/userServices';

export default class ArticleBreadCrumb extends Component {
  handleHomeClick = () => {
    userServices.sendAnalyticsEvents({
      category: 'Article Breadcrumbs',
      action: 'Clicks',
      label: 'Home'
    });
    window.location = '/';
  };

  insightsClick = () => {
    userServices.sendAnalyticsEvents({
      category: 'Article Breadcrumbs',
      action: 'Clicks',
      label: 'Insights'
    });
    window.location = '/insights';
  };

  render() {
    return (
      <div className='breadCrumb'>
        <a onClick={this.handleHomeClick}>home</a>
        <ChevronIcon />
        <a onClick={this.insightsClick}>insights</a>
      </div>
    );
  }
}
