import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import userServices from '../services/userServices';
import Header from '../components/header';
import SEO from '../components/seo';
import InsightArticle from '../components-pure/ArticleDetailsTopSection';
import ArticleContent from '../components-pure/ArticleDetailsBody';
import Layout from '../components-pure/Layout';
import ArticleBreadCrumb from '../components/ArticleBreadCrumb';
import SocialIcons from '../components/SocialIcons';
import HTMLContent from '../components/Content';
import { Title, ArticleDate, ArticleImage, Separator } from '../components-pure/ArticleDetails';

const keywords = [
  'National Freight Index',
  'road',
  'Trucking',
  'Prices Rates',
  'India',
  'Spot Rate',
  'Container',
  'Open',
  'Trailer',
  'rupees per ton km',
  'road trends'
];

export default class GenericBlog extends Component {
  state = {
    isUserLoggedIn: false,
    userMobile: null
  };

  async componentWillMount() {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        this.setState({ isXhrInProgress: true });
        let checkUser = await userServices.authenticateUser();
        this.setState({ isXhrInProgress: false });
        if (checkUser && checkUser.mobile && checkUser.mobile !== '') {
          window.localStorage.setItem('userMobile', checkUser.mobile);
          this.setState({ userMobile: checkUser.mobile, isUserLoggedIn: true });
        } else {
          window.localStorage.removeItem('sessionToken');
          window.localStorage.removeItem('userMobile');
        }
      }
    }
  }
  render() {
    const { isUserLoggedIn, userMobile } = this.state;
    const articleData = this.props.data.contentfulArticles;
    return (
      <div>
        <SEO keywords={keywords} />
        <Helmet>
          <base target='_blank' href='https://nationalfreightindex.com/' />
        </Helmet>
        <Header isUserLoggedIn={isUserLoggedIn} userMobile={userMobile} />
        <InsightArticle>
          <Layout>
            <div className='articleHead'>
              <div className='leftPane'>
                <ArticleBreadCrumb />
                <Title className='bigText'>{articleData.articleHeading}</Title>
                <div className='authorDetails'>
                  <ArticleDate className='bigText'>{articleData.publishDate}</ArticleDate>
                </div>
                <SocialIcons />
              </div>
              <div className='rightPane'>
                <ArticleImage src={articleData.thumbnail.fixed.src} alt='' className='md lg' />
              </div>
            </div>
            <ArticleContent>
              <HTMLContent className='content' content={articleData.articleDetail.childMarkdownRemark.html} />
            </ArticleContent>
            <Separator />
            <div className='authorDetails'>
              <p>
                Write to us at{' '}
                <a href='mailto:nfi@rivigo.com' style={{ color: '#e3383a' }}>
                  nfi@rivigo.com
                </a>
              </p>
            </div>
          </Layout>
        </InsightArticle>
      </div>
    );
  }
}

export const Pagequery = graphql`
  query ArticlesQuery($id: String!) {
    contentfulArticles(id: { eq: $id }) {
      order
      articleHeading
      articleUrl
      publishDate
      thumbnail {
        fixed(width: 1200) {
          src
        }
      }
      articleDetail {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

GenericBlog.propTypes = {
  data: PropTypes.object
};
