import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
`;

export default Layout;
