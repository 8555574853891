import styled from 'styled-components';
import { device } from '../constants/device';

const InsightArticle = styled.article`
  padding-bottom: 50px;
  .articleHead {
    display: flex;
    align-items: center;
    & > div {
      flex-grow: 1;
      width: 50%;
    }
  }
  .leftPane {
    padding: 20px 30px 20px 0;
  }
  .rightPane {
    img {
      border-radius: 0;
    }
  }
  .breadCrumb {
    color: #2a2a2a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    a {
      text-transform: uppercase;
      cursor: pointer;
      color: #2a2a2a;
    }
    svg {
      margin: 0 10px;
    }
  }
  .authorDetails {
    display: flex;
    img {
      margin-right: 30px;
    }
  }
  .socialIcons {
    display: flex;
    max-width: 280px;
    height: 52px;
    border: 1px solid #979797;
    border-radius: 4px;
    // margin-top: 25px;
    svg {
      width: 26px;
      height: 24px;
    }
    & > span {
      padding: 10px;
      font-size: 24px;
      color: #2a2a2a;
      max-width: 56px;
      text-align: center;
      border-right: 1px solid #979797;
      flex-grow: 1;
      cursor: pointer;
      &:last-child {
        border: none;
      }
    }
  }
  .socialIconsWrap {
    position: absolute;
    display: flex;
    max-width: 280px;
    height: 52px;
    top: 1px;
    width: 100%;
    & > div {
      padding: 10px;
      cursor: pointer;
      max-width: 56px;
      flex-grow: 1;
    }
  }
  @media ${device.tablet} {
    .articleHead {
      flex-direction: column-reverse;
      margin: 0 -15px;
      & > div {
        width: 100%;
      }
    }
    .leftPane {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`;

export default InsightArticle;
