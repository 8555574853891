import React, { Component } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton
} from 'react-share';
import PropTypes from 'prop-types';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, MailIcon } from '../custom-icons';
import userServices from '../services/userServices';

export default class SocialIcons extends Component {
  state = {
    url: ''
  };

  socialIconsClick = (e) => {
    userServices.sendAnalyticsEvents({
      category: 'Social Icons',
      action: 'Clicks',
      label: `${e.target.classList[1]} shared ${this.state.url}`
    });
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({ url: window.location.href });
    }
  }

  render() {
    const { url } = this.state;
    return (
      <div style={{ position: 'relative', marginTop: '25px' }}>
        <p className='socialIcons'>
          <span>
            <FacebookIcon />
          </span>
          <span>
            <LinkedinIcon />
          </span>
          <span>
            <TwitterIcon />
          </span>
          <span>
            <WhatsappIcon />
          </span>
          <span>
            <MailIcon />
          </span>
        </p>
        <p onClick={(e) => this.socialIconsClick(e)} className='socialIconsWrap'>
          <FacebookShareButton url={url} />
          <LinkedinShareButton url={url} />
          <TwitterShareButton url={url} />
          <WhatsappShareButton url={url} />
          <EmailShareButton url={url} />
        </p>
      </div>
    );
  }
}

SocialIcons.propTypes = {
  url: PropTypes.string
};
